<template>
  <div
    v-loading="loading"
    class="bordered"
  >
    <el-row :gutter="20">
      <el-col
        :xs="12"
        :md="6"
      >
        <div class="form-control">
          <label>
            Name
          </label>
          <el-input
            v-model="name"
            placeholder="Enter a Name that can be referenced to locate this request"
          />
        </div>
        <div class="form-control">
          <label>
            Organization
          </label>
          <org-picker :organization.sync="organization" />
        </div>
        <div class="form-control">
          <label>
            Tags
          </label>
          <tag-picker :tags.sync="tags" />
        </div>
        <div>
          <el-button
            :disabled="cannotUpload"
            type="primary"
            @click="handlePlaceRequest"
          >
            Upload
          </el-button>
        </div>
      </el-col>
      <el-col
        style="position: relative"
        :xs="12"
        :md="6"
      >
        <uploader
          ref="uploader"
          :accept-file-exts="fileExts"
          api-endpoint="/api/v1/geoframes/import/geojson"
          single-file
          upload-field-name="imports"
          @complete="data => $emit('complete', data)"
          @file-change="handleFileChange"
          @upload-state-change="handleStateChange"
        />
      </el-col>
      <el-col
        :xs="24"
        :md="12"
        class="help-text"
      >
        <p>Currently we only accept valid .csv files with a maximum size of 5,000 records. Please provide .csv files in the following format:</p>
        <p><pre>location name,tags,notes,start date,end date,street address,city,state,zip</pre></p>
        <p><strong>Guidelines:</strong> A valid organization must be selected. Dates must be between {{ rangeStart | inlineDateFormat('MM/DD/YYYY') }} and {{ rangeEndDate | inlineDateFormat('MM/DD/YYYY') }} and must be of the form MM/DD/YYYY. 'Notes' is an optional field and may be left blank. Depending on the program being used to enter orders and update the file, it may be necessary to export the file to .csv format rather than just saving it as .csv.</p>
        <p>
          Please download <a :href="orderTemplateUri">the CSV template</a>
          for an example.
        </p>
      </el-col>
    </el-row>

    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="dismissDialog"
      title="There were some problems with your upload"
    >
      <el-table
        :data="errors"
        stripe
      >
        <el-table-column
          :width="100"
          prop="row"
          label="Row"
        />
        <el-table-column
          prop="problem"
          label="Problem"
        />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import validator from 'validator';
import { mapGetters } from 'vuex';
import { inlineDateFormat, parseDate } from '../../helpers';
import { getSignedS3Url } from '../../methods/aws';
import papa from 'papaparse';
import _isEqual from 'lodash/isEqual';
import OrgPicker from '../global/OrgPicker';
import Uploader from '../global/Uploader.vue';
import TagPicker from '../global/TagPicker';
import { order as orderApi } from '@/adonis-api';

export default {
  components: {
    OrgPicker,
    TagPicker,
    Uploader,
  },
  filters: {
    inlineDateFormat,
  },

  data() {
    return {
      loading: false,
      dialogVisible: false,
      errors: [],
      fileList: [],
      fileExts: ['.csv'],
      name: '',
      organization: this.$store.state.user.orgDetails,
      tags: [],
    };
  },

  computed: {
    ...mapGetters('settings', [
      'orderTemplateUri',
      'rangeStart',
      'rangeEndDate',
    ]),
    cannotUpload() {
      return !this.name || this.fileList.length === 0;
    },
  },

  methods: {
    dismissDialog(done) {
      this.errors = [];
      done();
    },

    downloadTemplate() {
      getSignedS3Url
        .callPromise({
          uri: this.$store.state.settings.orders.templateUri,
        })
        .then(url => {
          window.location.href = url;
        })
        .catch(err => this.$reportError(err));
    },

    handleFileChange(fileList) {
      this.fileList = fileList;
    },

    handleStateChange(isUploading) {
      // Function is called but does nothing. Will leave for now to avoid negative side effects.
    },

    handlePlaceRequest() {
      this.loading = true;

      papa.parse(this.fileList[0], {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: async (results, file) => {
          const headings = results.meta.fields;
          const validatedHeadings = [
            'location name',
            'tags',
            'notes',
            'start date',
            'end date',
            'street address',
            'city',
            'state',
            'zip',
          ];
          if (!_isEqual(headings, validatedHeadings)) {
            this.loading = false;
            return this.$notify.error({
              title: 'Invalid Heading',
              message: 'Please verify you are using the latest csv template.',
              duration: 7500,
            });
          }

          const cleanRecords = [];

          for (const record of results.data.filter(r => r['street address'])) {
            let zip = '';

            if (record.zip) {
              zip = (
                '00000' +
                record.zip
                  .toString()
                  .replace(/\D/g, '')
                  .slice(0, 5)
              ).slice(-5);
              if (zip === '00000') zip = '';
            } else {
              zip = '';
            }

            const clean = {
              name: String(record['location name'])
                .replace(/[^a-z0-9 _\-&]/gi, '')
                .trim(),
              tags: String(record.tags || '')
                .split(',')
                .map(item => String(item).trim())
                .filter(tag => tag),
              notes:
                typeof record.notes === 'string'
                  ? record.notes.trim()
                  : record.notes,
              start_date: parseDate(record['start date'], 'M/D/YYYY'),
              end_date: parseDate(record['end date'], 'M/D/YYYY'),
              street_address: String(record['street address'])
                .replace(/[^a-z0-9 _\-&]/gi, '')
                .trim(),
              city: String(record.city)
                .replace(/[^a-z0-9 _\-&]/gi, '')
                .trim(),
              state: String(record.state)
                .replace(/[^a-z]/gi, '').trim()
                .toUpperCase(),
              zip,
            };

            let hasEmpties = false;
            for (const p in clean) {
              // Ignore empty notes or tags field
              if (p !== 'notes' && p !== 'tags' && p !== 'zip') {
                if (!clean[p]) {
                  hasEmpties = true;
                }
              }
            }

            if (!hasEmpties) {
              cleanRecords.push(clean);
            }
          }

          const fieldValidators = {
            name: val => val && validator.isLength(val, { min: 2, max: 512 }),
            tags: val =>
              !val || val.every(tag => validator.isLength(tag, { max: 512 })),
            notes: val => !val || validator.isLength(val, { max: 512 }),
            street_address: val =>
              val && validator.isLength(val, { min: 2, max: 512 }),
            start_date: val => {
              if (!(val instanceof Date)) return false;

              return (
                moment(val).isSameOrAfter(this.rangeStart.startOf('day')) &&
                moment(val).isSameOrBefore(moment(this.rangeEndDate))
              );
            },
            end_date: val => {
              if (!(val instanceof Date)) return false;

              return (
                moment(val).isSameOrAfter(this.rangeStart.startOf('day')) &&
                moment(val).isSameOrBefore(moment(this.rangeEndDate))
              );
            },
            city: val => val && validator.isLength(val, { min: 2, max: 128 }),
            state: val =>
              /^A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]$/.test(
                val,
              ),
            zip: val => val === '' || /\d{5}/.test(val),
          };

          const humanValidationRule = field => {
            switch (field) {
              case 'name':
              case 'street_address':
                return 'Must be a valid alpha-numeric string, 2-512 characters in length.';
              case 'city':
                return 'Must be a valid alpha-numeric string, 2-128 characters in length.';
              case 'state':
                return 'Must be a valid 2 character US state abbreviation.';
              case 'tags':
                return 'Must be a valid comma separated list of alpha-numeric values.';
              case 'start_date':
              case 'end_date':
                return `Must be a valid date between ${inlineDateFormat(
                  this.rangeStart,
                  'MM/DD/YYYY',
                )} - ${inlineDateFormat(
                  this.rangeEndDate,
                  'MM/DD/YYYY',
                )} in the format MM/DD/YYYY`;
              case 'zip':
                return 'Must be a 5 digit number';
              default:
                return '';
            }
          };

          let index = 0;
          let badRecord = false;
          const validationErrorList = [];

          for (const record of cleanRecords) {
            index++;
            if (record.start_date > record.end_date) {
              validationErrorList.push({
                row: index,
                problem: 'Start dates must come before end dates',
              });
              badRecord = true;
            }

            for (const field in record) {
              if (!fieldValidators[field](record[field])) {
                validationErrorList.push({
                  row: index,
                  problem: `Invalid ${field}. ${humanValidationRule(field)}`,
                });
                badRecord = true;
              }
            }
          }

          if (badRecord || cleanRecords.length < 1) {
            // Display validation problems
            this.loading = false;
            this.errors = validationErrorList;
            this.dialogVisible = true;
          } else {
            await orderApi.create({
              name: this.name,
              organization_id: this.organization.id,
              items: cleanRecords,
              tags: this.tags,
            });

            this.$message({
              message: `Processed ${cleanRecords.length} record${
                cleanRecords.length !== 1 ? 's' : ''
              } in file "${file.name}"`,
              type: 'success',
            });
            this.errors = [];
            this.dialogVisible = false;
            this.loading = false;
            this.name = '';
            this.tags = [];
            this.fileList = [];
            this.$refs.uploader.reset();

            this.$emit('upload');
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .el-tag {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
  .el-select {
    width: 60%;
  }
}
.el-upload {
  width: 100% !important;
  .el-upload-dragger {
    width: 100% !important;
  }
}

.bordered {
  margin-top: 1rem;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
}

.help-text p {
  font-size: 0.85rem;
  pre {
    font-size: 1rem;
    background: #ddd;
    padding: 5px;
    border: 1px solid #666;
  }
}
</style>
