<template>
  <div v-loading="busy">
    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="item"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      disable-action-menu
      disable-checkboxes
      disable-search
      :filters="{ statuses: statusFilter }"
      @action="handleAction"
    >
      <div slot="additional-controls">
        <filter-menu
          :filter.sync="statusFilter"
          :options="filterOptions"
        />
      </div>
      <el-table-column
        prop="name"
        label="Name"
        width="240"
      />
      <el-table-column label="Address">
        <template slot-scope="{ row }">
          {{ row.street_address }}, {{ row.city }}, {{ row.state }} {{ row.zip }}
        </template>
      </el-table-column>
      <el-table-column
        label="Created"
        prop="created_at"
        width="120"
      >
        <template slot-scope="{ row: orderItem }">
          {{ orderItem.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        label="Status"
        prop="status"
        width="100"
      />
      <el-table-column
        label="Notes"
      >
        <template slot-scope="{ row }">
          {{ row.notes }}
          <span
            v-if="row.process_note"
            class="danger"
          >
            (ERROR: {{ row.process_note }})
          </span>
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { order as orderApi } from '@/adonis-api';
import DataTable from '../global/DataTable.vue';
import FilterMenu from '../global/FilterMenu.vue';

export default {
  components: { DataTable, FilterMenu },
  props: {
    orderId: { type: Number, required: true },
  },

  data() {
    return {
      busy: false,
      dataTable: Object.freeze({
        actions: [],
        order: 'descending',
        sortBy: 'created_at',
      }),
      filterOptions: Object.freeze({
        label: 'Status',
        prop: 'status',
        choices: [
          {
            label: 'Pending',
            value: 'PENDING',
          },
          {
            label: 'Complete',
            value: 'COMPLETE',
          },
          {
            label: 'Error',
            value: 'ERROR',
          },
        ],
      }),
      statusFilter: [],
      item: Object.freeze({
        find: options => {
          return orderApi.getItems(this.orderId, options);
        },
      }),
    };
  },

  methods: {
    filterTag(value, row) {
      return true;
    },

    handleAction() {},
  },
};
</script>

<style>
</style>
