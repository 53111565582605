<template>
  <div>
    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="order"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      disable-archive
      disable-checkboxes
      @action="handleAction"
    >
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <order-detail :order-id="row.id" />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name"
        sortable="custom"
      />
      <el-table-column label="Progress">
        <template slot-scope="{ row: { __meta__: meta } }">
          <div class="progress-info">
            <div class="bar">
              <el-progress
                :percentage="getCompletionPercentage(meta)"
                :show-text="false"
              />
            </div>
            <div class="text">
              {{ Number(meta.error) + Number(meta.complete) }} /
              {{ meta.total_items }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Issues">
        <template slot-scope="{ row: { __meta__: meta } }">
          <span :class="getErrorClass(meta)">
            {{ meta.error }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="author"
        label="Author"
        :width="130"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.author | fullName }}
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.organization.name }}
        </template>
      </el-table-column>
      <el-table-column
        :width="110"
        prop="created_at"
        label="Created"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.created_at | dateString }}
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { order as orderApi } from '@/adonis-api';
import DataTable from '../global/DataTable.vue';
import OrderDetail from './OrderDetail.vue';

export default {
  components: { DataTable, OrderDetail },
  data() {
    return {
      dataTable: Object.freeze({
        actions: [
          {
            command: 'process',
            icon: 'cogs',
            label: 'Process',
          },
          {
            command: 'delete',
            icon: 'trash',
            label: 'Delete',
            type: 'danger',
          },
        ],
        order: 'descending',
        sortBy: 'created_at',
      }),
      order: Object.freeze(orderApi),
      selectedGeoframeIds: [],
    };
  },

  methods: {
    getCompletionPercentage(meta) {
      const errors = Number(meta.error);
      const complete = Number(meta.complete);
      const total = Number(meta.total_items);

      return parseInt((errors + complete) * 100 / total);
    },

    getErrorClass(meta) {
      return meta.error !== '0' ? 'errors' : '';
    },

    async handleAction(command, order) {
      switch (command) {
        case 'process':
          this.$router.push(`/process/address-list/${order.id}`);
          break;
        case 'delete':
          try {
            await this.$confirm(
              'Are you sure you wish to permanently delete this order?',
              'Delete Order',
              {
                type: 'warning',
              },
            );
          } catch (e) {
            return;
          }

          try {
            await orderApi.delete(order.id);
            this.$refs.dataTable.invokeQuery();
          } catch (e) {
            this.$notify.error({
              message: 'There was a problem deleting this order. This issue has been reported.',
            });
            this.$reportError(e);
          }

          break;
        default:
          break;
      }
    },

    reload() {
      this.$refs.dataTable.invokeQuery();
    },

    setSelection(geoframes) {
      this.selectedGeoframeIds = geoframes.map(geo => geo._id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.pagination {
  display: inline-block;
  margin: 16px 0;
}

.search-box {
  margin-bottom: 16px;
  max-width: 180px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin-left: 4px;
}

.progress-info {
  align-items: center;
  display: flex;

  .bar {
    width: 120px;
  }

  .text {
    margin-left: 1em;
  }
}

.errors {
  color: $--color-danger;
}
</style>
