<template>
  <div>
    <h3>Rapid Geoframe Builder</h3>
    <div class="sections">
      <div>
        <order-upload-form @upload="handleUpload" />
      </div>
      <div>
        <order-request-table ref="requestTable" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderRequestTable from '../../orders/OrderRequestTable';
import OrderUploadForm from '../../orders/OrderUploadForm';

export default {
  components: {
    OrderRequestTable,
    OrderUploadForm,
  },

  methods: {
    handleUpload() {
      this.$refs.requestTable.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.sections > div:not(:last-of-type) {
  margin-bottom: 1em;
}
</style>
